
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.itemQtyDialog = obj.itemQtyDialog;
      this.itemPrice = this.formatAmount(obj.itemPrice);
      this.itemQty = obj.itemQty;
    },
  },
  emits: ["emitItemQtyEvent"],
})
export default class ItemQty extends Vue {
  private toast;
  private itemQtyDialog = false;
  private itemQty = 0;
  private itemPrice = 0;
  private checkTxn;
  private value;
  private express = false;
  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }

    return Number(balance);
  }

  emitItemDetail(action) {
    const res = [action, this.itemQty, this.itemPrice];
    this.$emit("emitItemQtyEvent", res);
    this.itemQtyDialog = false;
  }

  counter(item, action) {
    if (action == "inc") {
      item.quantity++;
    } else {
      if (item.quantity <= 0) {
        this.toast.showWarning("Cannot decrease the quatity less then 0");
      } else {
        item.quantity--;
      }
    }
  }

  manageQty(action) {
    if (action == "inc") {
      this.itemQty++;
    } else {
      if (this.itemQty > 0) {
        this.itemQty--;
      } else {
        this.toast.showWarning("Could not make the qty less then zero");
      }
    }
  }
}
