<template>
  <Dialog
    v-model:visible="itemQtyDialog"
    :style="{ width: '60vw' }"
    :maximizable="false"
    :closable="false"
    position="top"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-d-flex p-jc-end">
      <div class="p-mb-2 p-mr-2 pull-right">
        <Button
          class="p-button-primary"
          icon="pi pi-check-circle"
          label="Done"
          @click="emitItemDetail('Done')"
        />
      </div>
      <div class="p-mb-2 p-mr-2 pull-right">
        <Button
          class="p-button-danger"
          icon="pi pi-times"
          label="Cancel"
          @click="emitItemDetail('Cancel')"
        />
      </div>
    </div>
    <div style="height: 0.2em; background-color: #eee" class="p-p-0">
      <ProgressBar
        v-if="progressBar"
        mode="indeterminate"
        style="height: 0.2em"
      />
    </div>
    <div class="row">
      <div class="col-md-4">
        <h5 class="p-mt-3">
          <i class="pi pi-bars"></i>
          Unit & Item Price
        </h5>
        <div class="p-field">
          <label for="Priority">Unit</label>
          <div class="p-inputgroup">
            <Button
              icon="pi pi-plus-circle"
              @click="manageQty('inc')"
              class="p-button-primary p-button-lg"
            />
            <InputNumber v-model="itemQty" intergeronly />
            <Button
              icon="pi pi-minus-circle"
              @click="manageQty('dec')"
              class="p-button-danger p-button-lg"
            />
          </div>
        </div>
        <div class="p-field">
          <label for="Priority">Price</label>
          <InputNumber
            v-model="itemPrice"
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>
      </div>
      <div class="col-md-8">
        <h5 class="p-mt-3">
          <i class="pi pi-cog"></i>
          OR Delete Item
        </h5>
        <div class="p-d-flex p-jc-end p-mt-4">
          <div>
            <Button
              icon="pi pi-times"
              @click="emitItemDetail('Delete')"
              class="p-button-danger"
              label="Delete Item"
            />
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.itemQtyDialog = obj.itemQtyDialog;
      this.itemPrice = this.formatAmount(obj.itemPrice);
      this.itemQty = obj.itemQty;
    },
  },
  emits: ["emitItemQtyEvent"],
})
export default class ItemQty extends Vue {
  private toast;
  private itemQtyDialog = false;
  private itemQty = 0;
  private itemPrice = 0;
  private checkTxn;
  private value;
  private express = false;
  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }

    return Number(balance);
  }

  emitItemDetail(action) {
    const res = [action, this.itemQty, this.itemPrice];
    this.$emit("emitItemQtyEvent", res);
    this.itemQtyDialog = false;
  }

  counter(item, action) {
    if (action == "inc") {
      item.quantity++;
    } else {
      if (item.quantity <= 0) {
        this.toast.showWarning("Cannot decrease the quatity less then 0");
      } else {
        item.quantity--;
      }
    }
  }

  manageQty(action) {
    if (action == "inc") {
      this.itemQty++;
    } else {
      if (this.itemQty > 0) {
        this.itemQty--;
      } else {
        this.toast.showWarning("Could not make the qty less then zero");
      }
    }
  }
}
</script>

<style scoped>
</style>
