
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";

interface ModifiedConditionType {
    conditionStatus: string;
    condittionPrice: number;
    itemId: string;
    itemSpId: string;
    itemSpName: string;
    parentItemId: string;
    sectionName: string;
    quantity: number;
    totalAmount: number;
    checkedItem: boolean;
}

@Options({
  props: {
    receiptDetail: Object
  },
  watch: {
    receiptDetail(obj) {
      this.itemDialog   = obj.dialogStatus;
      this.conditionsList   = obj.itemLists;
    },
  },
  emits: ["emitItemDetailEvent"],
})




export default class ItemConditions extends Vue {
  private toast;
  private itemDialog = false;
  private itemId = "";
  private scheduleId = "";
  private hasCondition = "";
  private checkTxn;
  private conditionsList: ModifiedConditionType [] = [];

  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get progressBar() {
   return this.store.getters.getProgressBar;
  }

  get ConditionsComputed()
  {

    this.conditionsList.forEach(e => {
      e.totalAmount = this.formatAmount(e.quantity * e.condittionPrice);
    });

    return this.conditionsList;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatAmount(value) {
    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  emitItemDetail()
  {
    this.$emit('emitItemDetailEvent',this.conditionsList);
    this.itemDialog = false;
  }

  counter(item,action)
  {
    if(action == 'inc')
    {
      item.quantity++;
    }
    else
    {
      if(item.quantity <= 0)
      {
        this.toast.showWarning('Cannot decrease the quatity less then 0');
      }
      else
      {
        item.quantity--;
      }
    }
  }

}
