<template>
  <Dialog
    v-model:visible="itemDialog"
    :style="{ width: '100vw'}"
    :maximizable="false"
    :closable="false"
    position="top"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-d-flex p-jc-between">
      <div class="p-mb-2 p-mr-2">
        <i class="pi pi-plus-circle"></i>
        Item Details
      </div>
      <div class="p-mb-2 p-mr-2 pull-right">
        <Button
          class="p-button-success p-button-lg"
          icon="pi pi-check-circle"
          label="Next"
          @click="emitItemDetail()"
        />
      </div>
    </div>
     <div style="height: 0.2em; background-color: #eee;" class="p-p-0">
      <ProgressBar
        v-if="progressBar"
        mode="indeterminate"
        style="height: 0.2em"
      />
    </div>
    <div class="row" v-if="conditionsList.length > 0">
            <div class="col-md-6 pull-left p-p-0" >
                <template v-for="items in ConditionsComputed" :key="items">
                    <div v-if="items.sectionName == 'section_1'" class="condition-style p-m-2">
                        <div class="p-d-flex p-jc-between">
                          <div class="p-mb-2 p-mr-2">
                              <input class="item-chk-box p-mr-1" v-model="items.checkedItem" type="checkbox" />
                              <label>{{items.itemSpName}}</label>
                          </div>
                          <div class="p-mb-2 p-mr-2 pull-right">
                            <InputText style="width:40px; flex:0;" :value="items.quantity" class="p-button-danger p-p-1 p-mr-1" readonly/>
                            <Button @click="counter(items,'inc')" icon="pi pi-plus" class="p-button-success p-p-1 p-mr-1"/>
                            <InputText style="width:70px; flex:0;" v-model="items.condittionPrice" class="p-p-1" />
                            <Button @click="counter(items,'dec')" icon="pi pi-minus" class="p-button-danger p-p-1 p-ml-1"/>
                            <InputText style="width:70px; flex:0;" class="p-button-danger p-p-1 p-ml-1" :value="items.totalAmount" readonly/>
                          </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-md-6 pull-left p-p-0" >
                <template v-for="items in ConditionsComputed" :key="items">
                    <div v-if="items.sectionName == 'section_2'" class="condition-style p-m-2">
                        <div class="p-d-flex p-jc-between">
                        <div class="p-mb-2 p-mr-2">
                            <input class="item-chk-box p-mr-1" v-model="items.checkedItem" type="checkbox" />
                            <label>{{items.itemSpName}}</label>
                        </div>
                        <div class="p-mb-2 p-mr-2 pull-right">
                            <InputText style="width:40px; flex:0;" :value="items.quantity" class="p-button-danger p-mr-1 p-p-1" readonly/>
                            <Button @click="counter(items,'inc')" icon="pi pi-plus" class="p-button-success p-p-1 p-mr-1"/>
                            <InputText style="width:60px; flex:0;" v-model="items.condittionPrice" class="p-p-1" />
                            <Button @click="counter(items,'dec')" icon="pi pi-minus" class="p-button-danger p-p-1 p-ml-1"/>
                            <InputText style="width:60px; flex:0;" class="p-button-danger p-ml-1 p-p-1" :value="items.totalAmount" readonly/>
                        </div>
                        </div>
                    </div>
                </template>
            </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";

interface ModifiedConditionType {
    conditionStatus: string;
    condittionPrice: number;
    itemId: string;
    itemSpId: string;
    itemSpName: string;
    parentItemId: string;
    sectionName: string;
    quantity: number;
    totalAmount: number;
    checkedItem: boolean;
}

@Options({
  props: {
    receiptDetail: Object
  },
  watch: {
    receiptDetail(obj) {
      this.itemDialog   = obj.dialogStatus;
      this.conditionsList   = obj.itemLists;
    },
  },
  emits: ["emitItemDetailEvent"],
})




export default class ItemConditions extends Vue {
  private toast;
  private itemDialog = false;
  private itemId = "";
  private scheduleId = "";
  private hasCondition = "";
  private checkTxn;
  private conditionsList: ModifiedConditionType [] = [];

  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get progressBar() {
   return this.store.getters.getProgressBar;
  }

  get ConditionsComputed()
  {

    this.conditionsList.forEach(e => {
      e.totalAmount = this.formatAmount(e.quantity * e.condittionPrice);
    });

    return this.conditionsList;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatAmount(value) {
    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  emitItemDetail()
  {
    this.$emit('emitItemDetailEvent',this.conditionsList);
    this.itemDialog = false;
  }

  counter(item,action)
  {
    if(action == 'inc')
    {
      item.quantity++;
    }
    else
    {
      if(item.quantity <= 0)
      {
        this.toast.showWarning('Cannot decrease the quatity less then 0');
      }
      else
      {
        item.quantity--;
      }
    }
  }

}
</script>

<style scoped>

.condition-style{
    font-size: 16px;
    border-bottom: 1px dotted #ccc;
}

.item-chk-box {
    width: 30px;
    height: 30px;
    padding: 0;
    vertical-align: center;
    position: relative;
    top: 8px;
}

</style>
